import store from '@/store';

export default {
  getDocServices: function () {
    var docs = [
      {
        id: 1,
        title: '기술 매뉴얼 제작',
        short_desc:
          '국내외 다양한 출판물을 편집한 경력이 있는 DTP 전문가가 최종 문서를 편집합니다.',
        cover: '/assets/images/documentation/documentation_first.jpg',
        content:
          '<strong>[풍부한 경험]</strong><br><br>전자제품, 가구, 소프트웨어, 산업 및 의료장비 등 다양한 브랜드의 제품 사용 설명서에 대한 경험이 풍부합니다. 국내외 다양한 출판물을 편집한 경력이 있는 DTP 전문가가 최종 문서를 편집합니다. 매뉴얼 번역시에 원본문서 파일을 기반으로 해외 각 언어의 특성에 맞추어 문서 내의 이미지 및 텍스트의 최종 편집을 진행합니다.<br><br><br><br><strong>[다양한 문서 포맷]</strong><br><br>웹, eBook, 인쇄(PDF) 등 다양한 출판 환경에 맞춰 작업해 드립니다. (Adobe InDesign, Frame Maker, QuarkXpress, MS Word, Powerpoint 등)',
        content_cover:
          '/assets/images/documentation/doc-service-bg1.jpg',
        url: 'production',
        images: [
          '/assets/images/documentation/doc-service-4.jpg',
          '/assets/images/documentation/doc-service-5.jpg',
        ],
      },
      {
        id: 2,
        title: '테크니컬 일러스트',
        short_desc:
          '최종 사용자가 제품 또는 서비스를 사용하는 기술을 이해할 수 있도록 기술 매뉴얼을 간단하게 만듭니다.​',
        cover: '/assets/images/documentation/doc-service-2.jpg',
        content:
          '<strong>고객의 개발 환경이 CAD 설계를 중심으로 이루어지는 경우에는 다양한 3D CAD 데이터를 기초로 테크니컬 일러스트 작업이 가능합니다. <br><br><br>고객의 개발 환경이 CAD 설계를 중심으로 이루어지는 경우에는 다양한 3D CAD 데이터를 기초로 테크니컬 일러스트 작업이 가능합니다.<br><br><br>CAD 데이터를 활용한 테크니컬 일러스트 작업은 대규모 일러스트 작업의 제작 기간 및 비용을 단축하여 신속한 시장 진입이 가능하도록 지원합니다.</strong>',
        content_cover:
          '/assets/images/documentation/doc-service-bg1.jpg',
        url: 'illustration',
        images: [
          '/assets/images/documentation/doc-service-7.jpg',
          '/assets/images/documentation/doc-service-6.jpg',
        ],
      },
      {
        id: 3,
        title: '증강현실(AR)매뉴얼',
        short_desc:
          '국내외 다양한 출판물을 편집한 경력이 있는 DTP 전문가가 최종 문서를 편집합니다.',
        cover: '/assets/images/documentation/doc-service-3.jpg',
        content:
          '<strong>현실 세계에 가상 물체를 겹쳐 보여주는 증강현실 기술과 매뉴얼을 겹합한 형태로 사용자가 보고 있는 화면에 텍스트나 3D 모델링을 형태로 구현하여 소비자의 이해를 돕습니다.<br><br><br>현실의 배경에 가상의 오트젝트를 띄우는 것을 기본으로 하기 때문에 실제품이 없더라도 언제든지 제품에 대한 정보를 얻을 수 있습니다.<br><br><br>고객사에서 보유하고 있는 기존의 제품 설계 데이터/제품 시나리오(매뉴얼)를 재활용하여 개발하기 때문에 제작 기간과 비용을 감소할 수 있습니다.</strong>',
        content_cover:
          '/assets/images/documentation/doc-service-bg1.jpg',
        url: 'ar',
        images: [
          '/assets/images/documentation/doc-service-8.jpg',
          '/assets/images/documentation/doc-service-9.jpg',
        ],
      },
      {
        id: 4,
        title: '3D 시뮬레이터',
        short_desc:
          '정확한 분석과 예측을 수행하여 생산성과 운영 효율성을 극대화 하는 최첨단 3D 시뮬레이터를 제공합니다.​',
        cover: '/assets/images/documentation/3d-Main.jpg',
        content:
          '<strong>텍스트리는 현실감 넘치는 그래픽과 직관적인 사용자 경험을 제공하는 최첨단 3D 시뮬레이터를 제공합니다.<br><br><br>제조, 교육, 의료, 군사 등 다양한 분야에서 활용되며 실제 환경과 동일한 조건을 가상으로 재현함으로써 리스크를 최소화하고, 학습과 훈련의 효율성을 높입니다.<br><br><br>고도로 정밀한 모델링과 애니메이션을 통해 복잡한 시스템의 동작을 실시간으로 시각화 할 수 있어, 사용자는 보다 정확한 분석과 예측을 수행할 수 있습니다.</strong>',
        content_cover: '/assets/images/documentation/3d-Main.jpg',
        url: 'simulator',
        images: [
          '/assets/images/documentation/3d-image1.jpg',
          '/assets/images/documentation/3d-image2.jpg',
        ],
      },
      {
        id: 5,
        title: '번역 솔루션',
        short_desc:
          '최선의 번역 및 현지화 서비스를 제공하고자 선도적인 솔루션 및 기술 파트너와 제휴를 맺고 있습니다.',
        cover: '/assets/images/documentation/documentation-5.jpg',
        content:
          '<p>Phrase는 텍스트리의 번역 및 현지화 기술 파트너입니다. <br>텍스트리는 현지화 전문 번역 관리 시스템(TMS)으로 전통적인 번역 기술과 특허 받은 AI 기술을 통해 비용 효율적인 번역 서비스를 제공하고 있습니다. <br>현지화 작업 흐름을 자동화하고, 번역 프로젝트를 관리하고, 기업용 번역 관리 시스템으로 비용 관리 및 품질 검사를 통해 엔드 투 엔드 번역 워크플로를 자동화합니다.<br></p><br><div><img src="/assets/images/documentation/doc-5-logo.png"/><br><br><span>비용 관리와 품질 관리가 가능한 중앙화 된 번역 관리 시스템</span><ul><li>번역 벤더, 언어 리소스 및 품질 검사를 하나의<br>중앙화 된 플랫폼에서 관리</li><li>자동 프로젝트 생성과 사용자 지정 가능한<br>워크플로우 단계 설정으로 프로젝트 완전 자동화</li><li>전문 번역가를 위한 모든 기능을 갖춘 강력한 번역 툴로<br>번역 생산성 향상</li><li>기계번역과 AI를 통한 비용 절감</li></ul></div>',
        content_cover:
          '/assets/images/documentation/documentation-5.jpg',
        url: 'phrase',
        images: [
          '/assets/images/documentation/doc-service-12.png',
          '/assets/images/documentation/doc-service-13.png',
        ],
      },
    ];
    store.commit('SET_DOCU_SERVICES', docs);
  },
};
