import axios from '@/plugins/axios';
import store from '@/store';

export default {
  getMonthlyViews: async function (year, month) {
    try {
      let data = {
        year: year,
        month: month,
        token: store.state.admin.session.token,
      };
      let response = await axios({
        method: 'post',
        url: '/api/blogs/monthly_views',
        data: data,
      });
      console.log('error');
      return response.data.blogs || [];
    } catch {
      return [];
    }
  },
  getBlogs: async function () {
    try {
      let data = {
        token: store.state.admin.session.token,
      };
      let response = await axios({
        method: 'post',
        url: '/api/blogs/admin_list',
        data: data,
      });
      console.log('error');
      return response.data.blogs || [];
    } catch {
      return [];
    }
  },
  getHiddenBlogs: async function () {
    try {
      let data = {
        token: store.state.admin.session.token,
      };
      let response = await axios({
        method: 'post',
        url: '/api/blogs/hidden',
        data: data,
      });
      console.log('error');
      return response.data.blogs || [];
    } catch {
      return [];
    }
  },

  getBlog: async function (blog_id) {
    try {
      let data = {
        blog_id: blog_id,
        token: store.state.admin.session.token,
      };
      let response = await axios({
        method: 'post',
        url: '/api/blogs/info',
        data: data,
      });
      return response.data.blog || {};
    } catch {
      return {};
    }
  },

  getBlogCategories: async function () {
    try {
      let data = {
        token: store.state.admin.session.token,
      };
      let response = await axios({
        method: 'post',
        url: '/api/blogs/categories',
        data: data,
      });
      return response.data.categories || [];
    } catch {
      return [];
    }
  },

  saveBlog: async function (blog) {
    try {
      let data = {
        blog: blog,
        token: store.state.admin.session.token,
      };
      let response = await axios({
        method: 'post',
        url: '/api/blogs/save',
        data: data,
      });

      return response.data.blog_id || 0;
    } catch {
      return 0;
    }
  },

  setBlogActive: async function (blog_id, active) {
    try {
      let data = {
        blog_id: blog_id,
        active: active,
        token: store.state.admin.session.token,
      };
      let response = await axios({
        method: 'post',
        url: '/api/blogs/toggle',
        data: data,
      });

      return response.data || false;
    } catch {
      return 0;
    }
  },

  deleteBlog: async function (blog_id) {
    try {
      let data = {
        blog_id: blog_id,
        token: store.state.admin.session.token,
      };
      let response = await axios({
        method: 'post',
        url: '/api/blogs/delete',
        data: data,
      });

      return response.data || false;
    } catch {
      return 0;
    }
  },
};
