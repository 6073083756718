import router from '@/router'

export default {
  state: {
    // session
    session: {
      token: '',
      master: {
        id: 0,
        email: ''
      },
      error: ''
    }
  },
  mutations: {
    // SESSION
    SET_ADMIN_SESSION_MASTER (state, master) {
      state.session.master.id = master.id;
      state.session.master.email = master.email;
    },
    SET_ADMIN_SESSION_TOKEN (state, token) {
      state.session.token = token;
      state.session.error = '';
    },
    SET_ADMIN_SESSION_LOGIN_ERROR (state, error) {
      state.session.error = error;
    }
  },
  actions: {
    'admin-session-login': function ({commit}, params) {
      if (params.token) {
        commit('SET_ADMIN_SESSION_TOKEN', params.token);
        commit('SET_ADMIN_SESSION_MASTER', {
          id: params.master_id,
          email: params.password
        });
        router.push('/admin/dashboard');
      }
    },
    'admin-session-logout': function ({commit}) {
      commit('SET_ADMIN_SESSION_TOKEN', '');
      commit('SET_ADMIN_SESSION_MASTER', {
        id: 0,
        email: ''
      });
      router.push('/admin/login');
    }
  },
  getters: {
  }
}
