import master from './master'
import documentation from './documentation'
import solution from './solution'
import portfolio from './portfolio'
import blog from './blog'
import contact from './contact'

import adminMedia from './admin/media'
import adminBlog from './admin/blog'
import adminPortfolio from './admin/portfolio'

export default {
  master: master,
  documentation: documentation,
  solution: solution,
  portfolio: portfolio,
  blog: blog,
  contact: contact,
  // admin
  admin: {
    media: adminMedia,
    blog: adminBlog,
    portfolio: adminPortfolio
  }
}
