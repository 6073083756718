export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(
        /* webpackChunkName: "view-home" */ '@/views/home/page.vue'
      ),
  },
  {
    path: '/localization',
    name: 'Localization',
    component: () =>
      import(
        /* webpackChunkName: "view-home" */ '@/views/localization/page-list.vue'
      ),
  },
];
