export default {
  state: {
    fill: false
  },
  mutations: {
    SET_MOBILE_MORE_FILL (state, fill) {
      state.fill = fill;
    }
  },
  actions: {
  },
  getters: {
  }
}
