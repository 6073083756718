import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import Admin from './admin'

import ModNav from './nav'
import ModMore from './more'
import ModMobileMore from './mobile-more'

import PageDocumentation from './documentation'
import PageSolution from './solution'
import PagePortfolio from './portfolio'
import PageBlog from './blog'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  modules: {
    admin: Admin,
    nav: ModNav,
    more: ModMore,
    mobileMore: ModMobileMore,
    documentation: PageDocumentation,
    solution: PageSolution,
    portfolio: PagePortfolio,
    blog: PageBlog
  },
  plugins: [vuexLocal.plugin],
})
