import axios from '@/plugins/axios'
import store from '@/store'

export default {

  login: function (params) {
    store.commit('SET_ADMIN_SESSION_LOGIN_ERROR', '');
    axios({
      method: 'post',
      url: '/api/master/login',
      data: params
    }).then(response => {
      if (response.data.token) {
        store.dispatch('admin-session-login', response.data);
      } else {
        store.commit('SET_ADMIN_SESSION_LOGIN_ERROR', 'Login failed: Incorrect email or password');
      }
    }).catch(() => {
      store.commit('SET_ADMIN_SESSION_LOGIN_ERROR', 'Login failed');
    });
  }

}
