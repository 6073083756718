export default {
  state: {
    services: []
  },
  mutations: {
    SET_DOCU_SERVICES (state, services) {
      state.services = services
    },
  },
  actions: {
  },
  getters: {
  }
}
