export const routes = [
  {
    alias: '/blog',
    path: '/blogs',
    name: 'Blogs',
    component: () =>
      import(
        /* webpackChunkName: "view-blog-list" */ '@/views/blog/category.vue'
      ),
  },
  {
    path: '/category/:url/page/:page',
    name: 'BlogsCategory',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "view-blog-list" */ '@/views/blog/category.vue'
      ),
  },
  {
    path: '/category',
    redirect: '/blogs',
  },
  {
    path: '/category/:url',
    redirect: '/category/:url/page/1',
  },
  {
    path: '/tag/:url',
    name: 'BlogsTag',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "view-blog-list" */ '@/views/blog/tag.vue'
      ),
  },
  {
    path: '/tag/:url/*',
    redirect: '/tag/:url',
  },
  {
    path: '/blog/:url',
    name: 'BlogContent',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "view-blog-list" */ '@/views/blog/blog.vue'
      ),
  },
];
