export default {
  state: {
    categories: [],
    searchList: [],
    popularList: [],
    tags: []
  },
  mutations: {
    SET_BLOG_LIST (state, blogs) {
      var bs = []
      for (let blog of blogs) {
        var b = blog 
        b.thumb_url = blog.thumb_url.replace(' ', '%20')
        bs.push(b)
      }
      state.searchList = bs
    },
    SET_BLOG_POPULAR (state, blogs) {
      state.popularList = blogs
    },
    SET_BLOG_CATEGORIES (state, categories) {
      state.categories = categories
    },
    SET_BLOG_TAGS (state, blogs) {
      var tags = []
      for (let blog of blogs) {
        if (blog.keywords) {
          for (let tag of blog.keywords.split(',')) {
            if (tag.trim()) {
              tags.push(tag.trim())
            }
          }
        }
      }
      tags = [...new Set(tags)]
      tags = tags.sort(() => 0.5 - Math.random())
      state.tags = tags
    },
  },
  actions: {
  },
  getters: {
  }
}
