export const routes = [
  {
    path: '/documentation',
    name: 'Documentation',
    component: () => import (/* webpackChunkName: "view-documentation-list" */ '@/views/documentation/page-list.vue')
  },
  {
    path: '/documentation/:url',
    name: 'DocumentationContent',
    props: true,
    component: () => import (/* webpackChunkName: "view-documentation-content" */ '@/views/documentation/page-content.vue')
  }
]
